import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles(theme => ({
  link: {
    color: "#000000",
    textDecoration: "none",
  },
  circle: {
    height: 48,
    width: 48,
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      height: 60,
      width: 60,
      borderRadius: 30,
    },
  },
  linkWrapper: {
    padding: theme.spacing(),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
}))

const LinkButton = ({ url, renderIcon, target = "_blank" }) => {
  const classes = useStyles()
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"))
  return (
    <div className={classes.linkWrapper}>
      <a href={url} target={target} className={classes.link}>
        <Box boxShadow={3} className={classes.circle}>
          {renderIcon(matches ? 32 : 24)}
        </Box>
      </a>
    </div>
  )
}

export default LinkButton
