import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import LinkButton from "../components/LinkButton"
import { Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { FiMail, FiPhone, FiFacebook, FiInstagram } from "react-icons/fi"
import { FaWhatsapp } from "react-icons/fa"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    maxHeight: "100vh",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  image: {
    height: 200,
    width: 200,
  },
  text: {
    textAlign: "center",
  },
}))

const IndexPage = () => {
  const theme = useTheme()
  const classes = useStyles()
  console.log(theme)
  return (
    <Layout>
      <SEO title="Home" />
      <Grid
        container
        className={classes.root}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <div className={classes.image}>
          <Image />
        </div>
        <Typography
          variant="h2"
          gutterBottom
          color="textPrimary"
          className={classes.text}
        >
          Karate Club
        </Typography>
        <Typography variant="h4" color="textPrimary" className={classes.text}>
          Sensei Roy Lee Gatjens
        </Typography>
        <Grid item container direction="row" justify="center" spacing={2}>
          <LinkButton
            url="https://wa.me/50684099999"
            renderIcon={size => <FaWhatsapp size={size} />}
            target="_self"
          />
          <LinkButton
            url="https://www.facebook.com/Kuroobicr"
            renderIcon={size => <FiFacebook size={size} />}
          />
          <LinkButton
            url="https://www.instagram.com/kuroobicr/"
            renderIcon={size => <FiInstagram size={size} />}
          />
          <LinkButton
            url="tel:+50684099999"
            renderIcon={size => <FiPhone size={size} />}
            target="_self"
          />
          <LinkButton
            url="mailto:info@kuroobicr.com"
            renderIcon={size => <FiMail size={size} />}
            target="_self"
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
